import { theme } from '../Theme';
import { css } from 'emotion';

export const EztradStyling = css`
  color: ${theme.colors.almostBlack};

  .hero-component .wrapper {
    .column-content {
      color: ${theme.colors.white};

      h1 {
        font-family: ${theme.fonts.secondary};
        font-size: 3.125rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      p {
        font-family: ${theme.fonts.primary};
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }

  .title-component {
    span {
      font-weight: 400;
      font-size: 1rem;
      text-transform: none;
      font-variant: normal;
    }

    h2 {
      font-weight: 700;
      font-size: 2.75rem;
      text-transform: uppercase;
    }
  }

  .box-component {
    background-color: ${theme.colors.pink};
    color: white;

    span {
      font-weight: 700;

      font-size: 0.875rem;
      text-transform: uppercase;
      font-variant: normal;
    }

    h2 {
      font-size: 2.75rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .information-component {
    background-color: ${theme.colors.apricot};
    .item-container {
      h2 {
        text-transform: uppercase;
        font-variant: normal;
        color: ${theme.colors.black};

        font-weight: 700;
        font-size: 0.875rem;
      }
      p {
        color: ${theme.colors.black};
        line-height: 140%;
        letter-spacing: 0.01em;
        font-weight: 500;
      }
      .image-container {
        background-color: ${theme.colors.black};
      }
    }
  }

  ${theme.above.md} {
    .hero-component .column-content {
      background-color: rgba(252, 209, 175, 0.9);
    }
  }

  ${theme.below.md} {
    .hero-component .wrapper {
      background-color: ${theme.colors.blue};
      h1 {
        font-size: 2.25rem;
      }
    }
  }

  ${theme.below.sm} {
    .hero-component h1 {
      font-size: 2.25rem;
    }

    .title-component h2 {
      font-size: 2rem;
    }
  }
`;
